import '../css/main.scss';
console.log('Hello Claude')

function updateViewportHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// Initial update
updateViewportHeight();

// Update on resize
window.addEventListener('resize', updateViewportHeight);